@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
* {
  font-family: "Open Sans", sans-serif;
}
.auto-button-group {
  display: flex;
}
.btn {
  color: #ffffff;
  border-radius: 1px;
  z-index: 1;
  overflow: hidden;
  font-size: 14px;
  padding: 8px 20px;
  position: relative;
}
.btn-bg3 {
  background-image: linear-gradient(90deg, rgb(255 0 47) 0%, rgb(249 142 142) 100%);
  padding: 8px 40px;
}
.btn-bg4 {
  background: #ffffff07;
  padding: 7px 40px;
  border: 2px solid rgb(192, 192, 192);
  color: black;
  margin-left: 10px;
  text-align: center;
}
.btn-bg4:hover {
  background-image: linear-gradient(90deg, rgb(255 0 47) 0%, rgb(249 142 142) 100%);
  border: none;
  padding: 9px 40px;
  color: white;
}
.mx-center {
  margin-left: auto !important;
  margin-right: auto !important;
}
.position-relative{
  position: relative;
}

.home-banner{
  margin-top: 100px;
}
.home-banner .video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.home-banner .video video {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.banner-content {
  position: relative;
  min-height: 90vh;
  z-index: 2;
  width: 100%;
  display: flex;
  height: 100%;
  background: #0a0a0a9e;
  align-items: center;
  justify-content: center;
  color: white;
}
.banner-content h1 {
  font-size: 45px;
  font-weight: 900;
  color: white;
}

.form-wrap {
  padding-right: 15px;
}
.content-padding {
  padding-top: 20px;
  margin: 0 auto;
}
.heading {
  margin: 30px 0 10px 0;
  text-align: center;
}
.footer-item li{
  list-style: none !important;
}
.heading h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 0;

  color: rgb(255 0 47);
}
.heading p {
  margin-top: 20px;
}

.how-work-wrapper{
  padding-bottom: 15px !important;
}

.login-modal-wrapper {
  margin-top: 10px;
}

.none {
  display: none;
}

/* top banner styls */

.page-wrapper .page {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
.page-wrapper .left {
  width: 45%;
  padding: 0 70px;
}
.page-wrapper .right {
  width: 55%;
}

.left .text h1 {
  background-image: linear-gradient(90deg, rgb(255 0 47) 0%, rgb(249 142 142) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  font-weight: 700;
  color: rgb(117, 117, 117);
  margin-top: 50px;
}
.text p {
  color: rgb(0, 0, 0);
  font-weight: 400;
  font-size: medium;
}
/* rozerpay styls */
.roz-pay-wrapper {
  background-color: #ffffff;
  padding-bottom: 0;
  margin-top: 50px;
  padding: 20px 0;
  box-shadow: 5px 5px 10px #a5a5a5a3;
}
.roz-pay-wrapper p {
  margin-bottom: 0;
}
.roz_img {
  width: 70%;

}
/* left login form */

.input-wrapper label,
.check label {
  color: #444444;
  font-size: 15px;
}
.check .ant-form-item {
  margin-bottom: 0;
}
.form .ant-input {
  display: block;
  width: 100%;
  height: auto;
  font-size: 16px;
  color: #000;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid;
  border-bottom-color: rgba(165, 165, 165, 0.699);
  outline: none;
}
.form .ant-input:focus {
  border-bottom-color: rgba(159, 49, 248, 0.856);
  box-shadow: none;
}
.form .ant-input::placeholder {
  color: #a3a3a3;
  font-size: 14px;
}

.term {
  color: #555555;
}
.term a {
  color: #ff0202;
  font-weight: 700;
  font-size: 17px;
}


.faq {
  padding-bottom: 50px !important;
}
/* about page and refral page */
.about-home {
  position: relative;
}
.about-home img {
  height: 350px;
  width: 100%;
  object-fit: cover;
}
.about-home .wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-home .wrapper h2 {
  color: white;
}

.details {
  background-color: rgb(0, 0, 0);
  color: white;
}
.value-two__right-content {
  width: 100%;
  padding: 50px 30px;
  padding-bottom: 111px;
}
.about .image-box{
  padding-right: 0 !important;

}
.footer{
background: #000;
padding-bottom: 15px;
}


@media (max-width: 768px) {
  .login-modal-wrapper {
    padding: 15px;
  }
  .Free-survey-wrapper {
    display: none;
  }
  .wrap {
    flex-wrap: wrap;
  }
  .left .text h1 {
    margin-top: 20px;
  }

  .left .text {
    margin-top: 10px;
    text-align: center;
  }
  .page-wrapper .left,
  .page-wrapper .right {
    width: 100%;
  }
  .clinntwrap {
    display: block;
    padding: 30px 12px;
  }
  .footer-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    align-items: center;
  }
  .wrap-md-revers{
  flex-wrap: wrap-reverse;
  }
}
@media (max-width: 570px) {
  .auto-button-group {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .button-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .btn-bg3,
  .btn-bg4 {
    width: 48%;
  }
  .roz-pay-wrapper {
  text-align: center;
  padding-bottom: 25px;
  }
  
  .roz_img {
    margin: 15px auto;

  
  }
  .page-wrapper .left {
    padding: 15px;
  }
  .about .image-box{
    padding-right: 15px;
  
  }
  
}
