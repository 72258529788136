header {
    width: 100%;
    top: 0;
    z-index: 1000;
    background: rgb(0, 0, 0);
    position: fixed;
    top: 0;
  }

  .header {
    box-shadow: 0 0 10px #9f9f9f6e;
    position: relative;
  }
  header a{
    color: white !important;
  }

  .logo-img {
    width: 250px !important;
  }
  .logo a.active {
    border-bottom: none !important;
  }
  /* nav part start ----------------------------- */

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    position: relative;

  }
 

  nav .main-ul {
    display: flex;
    margin: 0;
  }
  nav .main-ul li a.active {
    border-bottom: none;
    color: rgb(255, 0, 0);
}

  nav .main-ul li {
    padding: 30px 15px;
    transition: 1s;
    color: white;
  }

 

  nav .main-ul li .switch-toggle {
    margin-left: 10px;
  }

  .nav-top-mobile {
    display: none;
  }

  .drop {
  
    justify-content: space-between;
    display: flex;
    position: absolute;
    border-bottom: none;
    width: calc(100% - 230px);
    justify-content: flex-start;
    transform: translate(-50%, 0);
    top: 100%;
    left: 50%;
    background: rgb(0, 0, 0);
    overflow: hidden;
    z-index: -1;
    height: 0;
    transition: all .5s ease-out;
  }

  .drop .img {
    width: 30%;
    height: 100%;
    overflow: hidden;
  }

  .drop .img img {
    width: 100%;
    object-fit: cover;
    display: block;
    transition: 0s;
  }

  .drop .img .text {
    background: rgb(255 0 47);
    color: rgb(255, 255, 255);
    padding: 10px;
    text-align: left;
  }

  .header h2 {
    margin-bottom: 0;
    color: #fdfdfd;

  }

  .drop .about-info{
    width: 70%;
    padding: 10px 20px;
    border-left: 1px solid rgb(245, 244, 244);
    position: relative;
  }

  .drop .about-info p{

    font-size: 16px;
    font-weight: 300;
    word-spacing: 5px;
    text-align: justify;
    color: rgb(255, 255, 255);
 }

  .btn-drop-box {
    background: rgb(255 0 47);
    width: 250px;
    padding: 10px 0;
    height: auto;
    font-size: 14px;
    word-wrap: break-word;
    margin-top: 90px;
    position: absolute;
    bottom: 20px;
    text-align: center;
    color: rgb(255, 255, 255) !important;
    right: 10px;
  }


  .btn-drop-box:hover {
    background: rgb(255 0 47);
    color: rgb(255, 255, 255);
  }

 
  .hamburger{
    display: none;
   }


  @media (min-width:851px) {
    .main-ul li:hover>.drop {

      z-index: 100;
      height: 240px;
    }

  }

  @media (max-width:850px) {

    .logo-img {
      width: 200px !important;
    }
    nav {
      padding: 20px;
    }

    .menu {
      width: 300px;
      position: absolute;
      top: 95px;
      left: -100%;
      transition: .5s ease-in-out;
      background: rgb(0, 0, 0);
    height: 100vh;
      overflow: hidden;
      overflow-y: scroll;
      z-index: 10000;
    }

    .menu.active {
      left: 0;
    }

    nav .main-ul {
      padding: 10px 30px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: right;
    }

    nav .main-ul li {
      width: 100%;
      display: block;
      text-align: left;
      margin: 10px 0;
      padding: 5px 0;
    }

    nav .main-ul li a {
      font-size: 18px;
    }

    nav .main-ul li .switch-toggle {
      float: right;
      margin-top: 4px;
      padding: 0 20px;
    }

    .hamburger{
        display: block;
       }

    .drop {
      width: 100%;
      padding: 0;
      display: none;
      position: relative;
      top: 0;
      left: 50%;
      background: rgb(0, 0, 0);
      border: none;
      height: auto;
      z-index: 1000;
    }

    .drop .img {
      display: none;
    }
    .drop .about-info{
      width: 100%;
    }
    .btn-drop-box {
      position: relative;
      width: 100%;
      display: inline-block;
      bottom: 0;
      right: 0;
      margin-top: 0;
     
    }
    .drop.open{
      display: block;

    }
  }

  @media (max-width:500px) {
    .menu {
      width: 100%;
    }
  }
