* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  
}
.app{
  overflow-x: hidden;
}
/* HTML5 display-role reset for older browsers */

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none !important;
  color: #0b093b;
}
a:hover {
  color: #fc0015;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #fff inset; /* Change the color to your own background color */
  box-shadow: transparent;
  -webkit-text-fill-color: #fff;
}
body {
  background: #fff;
  color: #0b093b;
  font-family: "Khula", sans-serif;
  overflow-x: hidden;
  font-size: 16px;
  font-weight: 400;
}

.card-login {
  width: 200%;
  max-width: 500px;
  margin: auto;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card-signup .sign-up-gateways .ant-btn {
  margin-right: 10px;
  margin-left: 10px;
  height: 60px;
  width: 70px;
  box-shadow: none;
  border-radius: 6px;
}

.card-login .sign-up-gateways .ant-btn img {
  width: 20px;
}
.container {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px 30px;
  
 
}
@media only screen and (max-width: 859px) {
  .container {
    padding: 0 15px;
  }
}
.regular {
  font-weight: 400 !important;
}
.semiBold {
  font-weight: 600 !important;
}
.extraBold {
  font-weight: 800 !important;
}

/* HELLPERS */
.textCenter {
  text-align: center;
}
.animate {
  transition: 0.3s ease;
}
.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.x100 {
  width: 100%;
}
.active {
  border-bottom: 2px solid #ff002f;
  color: #ff002f;
}
.tag {
  padding: 5px 10px;
  margin: 10px 0;
}
.shadow {
  box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
}
/* FLEXBOX */
.flex {
  display: flex;
}
.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexNullCenter {
  display: flex;
  align-items: center;
}
.flexColumn {
  flex-direction: column;
}
.flexstartCenter {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}

.pl20{
padding-left: 20px;
}
.pr20{
  padding-right: 20px;
  }

/* FONT SIZES */
.font11 {
  font-size: 0.688rem;
}
.font12 {
  font-size: 0.75rem;
}
.font13 {
  font-size: 0.813rem;
}
.font14 {
  font-size: 0.875rem;
}
.font15 {
  font-size: 0.938rem;
}
.font18 {
  font-size: 1.125rem;
}
.font20 {
  font-size: 1.25rem;
}
.font25 {
  font-size: 1.563rem;
}
.font30 {
  font-size: 1.875rem;
}
.font40 {
  font-size: 2.5rem;
}
.font60 {
  font-size: 3.75rem;
  line-height: 4.5rem;
}

.mt-16 {
  margin-top: 16px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-100{
  margin-top: 100px;
}
.w-100 {
  width: 100% !important;
}
@media only screen and (max-width: 859px) {
  .mb-120 {
    margin-bottom: 20px;
  }
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.688rem;
  }
  .font13 {
    font-size: 0.75rem;
  }
  .font14 {
    font-size: 0.813rem;
  }
  .font15 {
    font-size: 0.813rem;
  }
  .font18 {
    font-size: 0.938rem;
  }
  .font20 {
    font-size: 1.125rem;
  }
  .font25 {
    font-size: 1.25rem;
  }
  .font30 {
    font-size: 1.5rem;
  }
  .font40 {
    font-size: 1.875rem;
  }
  .font60 {
    font-size: 2.8rem;
  }
}
/* BORDER RADIUS */
.radius8 {
  border-radius: 1rem;
}
.radius6 {
  border-radius: 0.6rem;
}
/* COLORS */
.darkColor {
  color: #0b093b;
}
.purpleColor {
  color: #7620ff;
}
.orangeColor {
  color: #f2b300;
}
.lightColor {
  color: #f5f5f5;
}
.coralColor {
  color: #c4ffff;
}
.greenColor {
  color: #49cb86;
}
.greyColor {
  color: #707070;
}
.whiteColor {
  color: #fff !important;
}
/* BACKGROUNDS */
.darkBg {
  background-color: #ff002f;
}
.bgblack{
  background: black;
}
.purpleBg {
  background-color: #7620ff;
}
.orangeBg {
  background-color: #f2b300;
}
.lightBg {
  background-color: #f5f5f5;
}
.coralBg {
  background-color: #c4ffff;
}
.greenBg {
  background-color: #49cb86;
}
.greyBg {
  background-color: #707070;
}
.whiteBg {
  background-color: #fff;
}
.bgdark{
  background-color: #f7f5f5;
}
.faq-card{
  margin-top: 20px;
}
.btLdU{
  max-width: 100% !important;

}
.pt-20{
  padding-top: 20px;
}

.row-m{
  margin: 0;

}
.py15{
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (max-width: 560px) {
  .wrap{
    flex-wrap: wrap;
  }
}